@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Roboto:wght@500&family=Inconsolata:wght@500&display=swap);
.MuiInputBase-input {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1px #d5daeb;
  font-size: 48px;
}

.recharts-legend-wrapper {
  margin: 20px 0 0 58px;
};




.CircularProgressbar-path {
  stroke-width: 6;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
  stroke-linecap: round;
}

.CircularProgressbar {
  overflow: inherit;
}

svg:not(:root) {
    overflow: inherit !important;
}


