
.CircularProgressbar-path {
  stroke-width: 6;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
  stroke-linecap: round;
}

.CircularProgressbar {
  overflow: inherit;
}

svg:not(:root) {
    overflow: inherit !important;
}